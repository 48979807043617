import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Grid, H } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

import GuidesBanner from 'components/GuidesBanner'
import ArticleCard from 'components/ArticleCard'

const StyledGridItem = styled(Grid.Item)`
  margin-bottom: ${GTR.S};
  ${({ $isGroup }) =>
    $isGroup &&
    `
    display: grid;
    grid-template-rows: auto auto;
  `}
`

const SingleArticle = ({ article, noImage, isLarge, isBasic }) => (
  <ArticleCard
    type={article.type}
    title={article.title}
    slug={article.slug}
    image={noImage ? null : article.image}
    description={article?.description?.description}
    publishedAt={article.publishedAt}
    large={isLarge}
    isBasic={isBasic}
  />
)

const ArticlesGroup = ({ articles, isBasic }) => (
  <>
    {articles.map((article) => (
      <SingleArticle
        article={article}
        key={article.id}
        noImage
        isBasic={isBasic}
      />
    ))}
  </>
)

const ArticleList = ({ articles, title, isBasic }) => (
  <Wrapper background={COLOR.WHITE}>
    <Wrapper container>
      <H size="XL" decorative>
        {title}
      </H>
    </Wrapper>

    <Grid container containerPaddingTop={0}>
      {articles.map((articlesGroup, index) => {
        const isLarge = index === 0 && articlesGroup.length === 1
        return (
          <StyledGridItem
            key={articlesGroup[0].id}
            span={12}
            spanFromM={isLarge ? 12 : 6}
            spanFromL={isLarge ? 12 : 4}
            $isGroup={articlesGroup.length > 1}
          >
            {articlesGroup.length > 1 ? (
              <ArticlesGroup articles={articlesGroup} isBasic={isBasic} />
            ) : (
              <SingleArticle
                article={articlesGroup[0]}
                isLarge={isLarge}
                isBasic={isBasic}
              />
            )}
          </StyledGridItem>
        )
      })}
    </Grid>

    <GuidesBanner />
  </Wrapper>
)

ArticlesGroup.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired, // eslint-disable-line react/forbid-prop-types
  isBasic: PropTypes.bool,
}

SingleArticle.propTypes = {
  article: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  noImage: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  isBasic: PropTypes.bool,
}

ArticleList.propTypes = {
  articles: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
  isBasic: PropTypes.bool,
}

ArticleList.defaultProps = {
  isBasic: false,
}

ArticlesGroup.defaultProps = {
  isBasic: false,
}

SingleArticle.defaultProps = {
  isBasic: false,
}

export default ArticleList
